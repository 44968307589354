.Header{
	background-color: #003DA0;
	color: white;

	top: 0;
	left: 0;

	height: var(--header-height);

	padding-left: 20px;
	padding-right: 20px;

	flex: 0 0 auto;
}

.Header nav{
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.Header nav a{
	display: block;
	color: white;
	text-decoration: none;
	padding: 20px;
}

.Header button {
	color: #F2F2EA;
}
