.Footer{
	display: flex;
	justify-content: center;
	align-items: center;
	color: grey;
	border-top: 1px solid grey;

	width: 100%;
	height: 80px;

	flex: 0 0 auto;
}
