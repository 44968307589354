.ScoreChangerForm {
	padding: 16px;
}

.ScoreChangerForm > label {
 	display: block;
	margin: 8px;
}

.ScoreChangerForm > button {
	margin: 16px auto;
}

.ScoreChangerFormError > * {
	font-size: small;
}