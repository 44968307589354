.RichemontReport {
	width: calc(100vw - 2 * var(--padding));
	height: calc(100vh - var(--header-height) - var(--padding));
	overflow-x: auto;
}

.RichemontReport table {
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: medium;
	border-collapse: collapse;
}

.RichemontReport th {
	padding: 8px;
	background-color: darkslategrey;
	color: white;
	position: sticky;
	top: 0;
	z-index: 99;
}

.RichemontReport td {
	padding: 8px;
}

.RichemontReport .RichemontImage {
	height: 200px;
	padding: 4px;
}

.RichemontReport .ImageSlider {
	overflow: auto;
	white-space: nowrap;
	align-items: center;
	text-align: center;
	margin: 0 auto;
	display: flex;
	align-items: center;
}

.RichemontReport .Popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.RichemontReport .InnerPopup {
	position: absolute;
	left: 10%;
	right: 10%;
	top: 10%;
	bottom: 10%;
	max-width: 80%;
	max-height: 80%;
	min-width: 30%;
	min-height: 30%;
	margin: auto;
	width: min-content;
	background: white;
	align-items: center;
	padding: 16px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.RichemontReport .PopupContent {
	flex: 1 1;
	display: flex;
	margin: 4px;
	height: 100%;
	width: 100%;
	min-height: 20%;
	min-width: 20%;
	justify-content: center;
}

.RichemontReport .PopupContent>* {
	min-width: 0;
	min-height: 0;
	object-fit: contain;
	overflow: auto;
}

.RichemontReport .PopupCloseButton {
	margin: 4px;
	flex: 0 0;
}

.RichemontReport .ReportActions {
	display: flex;
	flex-direction: column;
}

.RichemontReport .ReportActions>* {
	padding: 8px;
	margin: 4px;
}

.RichemontReport .Score {
	color: green;
	font-weight: bold;
}

.RichemontReport .ScoreNA {
	font-weight: 200;
}

.RichemontReport .ScoreOverride {
	text-decoration: underline;
	text-decoration-style: solid;
	text-decoration-thickness: 2px;
}

.RichemontReport .TooltipText {
	visibility: hidden;
	width: 120px;
	background-color: grey;
	color: #fff;
	text-align: center;
	padding: 4px;
	border-radius: 6px;
	font-size: small;

	position: absolute;
	z-index: 1;
	top: 100%;
	left: 50%;
	margin-left: -60px;
	margin-top: 4px;
}

.RichemontReport .Tooltip {
	position: relative;
	display: inline-block;
}

.RichemontReport .Tooltip:hover .TooltipText {
	visibility: visible;
}

.RichemontReport .IdentifiedFeatures>* {
	margin: 8px;
	white-space: nowrap;
}

.RichemontReport .CounterfeitBadge {
	background-color: red;
	color: white;
	padding: 6px 8px 8px 8px;
	text-align: center;
	border-radius: 5px;
	font-weight: bold;
	font-size: small;
	width: min-content;
	margin: auto;
}

.RichemontReport .ClearedBadge {
	background-color: green;
	color: white;
	padding: 6px 8px 8px 8px;
	text-align: center;
	border-radius: 5px;
	font-weight: bold;
	font-size: small;
	width: min-content;
	margin: auto;
}

.RichemontReport .TBCBadge {
	background-color: grey;
	color: white;
	padding: 6px 8px 8px 8px;
	text-align: center;
	border-radius: 5px;
	font-weight: bold;
	font-size: small;
	width: min-content;
	margin: auto;
}

.RichemontReport .CounterfeitBadgeDate {
	font-size: smaller;
	white-space: nowrap;
}

.RichemontReport .Pagination {
	margin: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.RichemontReport .Pagination>* {
	margin: 16px;
	padding: 8px;
}

.ScoreThresholdDivider {
	border-top: 2px dotted red;
}

.RichemontReport .MuiTableSortLabel-root .Mui-active {
	color: red;
}
