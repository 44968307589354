.itemPage {
	text-align: center;
}

.thumbs {
	display: flex;
	justify-content: center;
	margin: 0px;
	padding: 0px;
}

.carouselImgContainer {
	max-height: 600px;
}

.carouselImgContainer img {
	object-fit: contain;
	max-height: 600px;
}
