.ReportPage .filters {
	display: flex;
}

.ReportPage .imgCenter {
	height: 200px;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px;
}

.ReportPage img {
	max-height: 200px;
	max-width: 200px;
	box-shadow: 1px 1px 1px #0005;
}

.ReportPage table {
	width: 100%;
	border-collapse: collapse;
}

.ReportPage table th {
	position: sticky;
	top: 0;
	background-color: #333;
	color: white;
	padding: 5px;
}

.ReportPage table th.sortable {
	cursor: pointer;
}

.ReportPage .sort {
	transition: transform 0.5s;
	transform: scaleY(1);
}

.ReportPage .sort.flip {
	transform: scaleY(-1);
}
