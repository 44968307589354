.RichemontReportPage {
	width: 100%;
}

.RichemontReportPage>* {
	padding: var(--padding);
}

.ReportOptions>* {
	margin-top: 8px;
	display: block;
}

.ReportLoading,
.ReportError,
.ReportEmpty {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	height: 100%;
	align-items: center;
	text-align: center;
}
