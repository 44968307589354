.scrapers-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.table-container {
	max-width: 95%;
}

th {
	font-weight: bold;
}

.source-id {
	font-style: italic;
	font-size: smaller;
}

.actions-container {
	display: flex;
	justify-content: center;
}

.actions-container > * {
	margin: 0 4px 0 4px;
}

/* Remove bottom border from table row */
tbody > .MuiTableRow-root:last-child > *{
	border-bottom: none;
}
