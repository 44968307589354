.error-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.error-card {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 16px;
	margin: auto;
}

.error-card > * {
	flex: 0 0 auto;
	color: #003DA0;
	margin: 2px;
}

.error-card button {
	margin-top: 4px;
	margin-bottom: -4px;
}

.error-title {
	display: flex;
	align-items: center;
}

.error-title > * {
	margin: 4px;
}
