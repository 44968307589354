:root{
	--header-height: 80px;
	--fold-height: calc(100vh - var(--header-height)); /* height a page can be before the fold (ie when the user has to scroll */
	--padding: 12px;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;
}


body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: #efefef;
	height: 100vh;
}

main {
	flex: 1 1 auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
